import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import PriceBuckets from './pages/price/PriceBuckets';
import YearBuckets from './pages/year/YearBuckets';
import Services from './pages/services/Services';
import Layout from './shared/Layout';
import Publish from './pages/publish/Publish';

export default createBrowserRouter([
    {
        path: '/',
        element: <Layout><App /></Layout>,
        // loader: () => {
        //     console.log('hey');
        //     return {
        //         text: 'hey',
        //     };
        // },
        children: [],
    },
    {
        path: '/price-buckets',
        element: <Layout><PriceBuckets /></Layout>,
        // loader: async () => {
        //     console.log('hey from price-buckets');
        //     const result = await new Promise((res, rej) => {
        //         setTimeout(() => {
        //             res({
        //                 text: 'hey from price-buckets',
        //             });
        //         }, 2000);
        //     })
        //     return result;
        // },
    },
    {
        path: '/year-buckets',
        element: <Layout><YearBuckets /></Layout>,
        // loader: () => {
        //     console.log('hey from another');
        //     return {
        //         text: 'hey from another',
        //     };
        // },
    },
    {
        path: '/services',
        element: <Layout><Services /></Layout>,
        // loader: () => {
        //     console.log('hey from another');
        //     return {
        //         text: 'hey from another',
        //     };
        // },
    },
    {
        path: '/publish',
        element: <Layout><Publish /></Layout>,
        // loader: () => {
        //     console.log('hey from another');
        //     return {
        //         text: 'hey from another',
        //     };
        // },
    },
]);
