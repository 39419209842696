import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { RouterProvider } from 'react-router-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import router from './router';
import firebaseConfig from './firebaseConfig.json';

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDatabase = getDatabase(firebaseApp);
const auth = getAuth(firebaseApp);
export const logout = () => {
    signOut(auth);
};
const login = () => {
    signInWithPopup(auth, new GoogleAuthProvider(), );
};

function Base() {
    const [user, loading, error] = useAuthState(auth);
    // console.log(user?.email);
    // console.log(user?.uid);
    if (loading) {
        return (
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    }
    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }
    if (user) {
        return (
            <React.StrictMode>
                <RouterProvider router={router} />
            </React.StrictMode>
        );
    }
    return (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <button className="btn btn-primary" onClick={login}>
                Log in
            </button>
        </div>
    );
}

ReactDOM.createRoot(document.getElementById('root')).render(<Base />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
