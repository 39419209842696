import { child, push, ref, set, update } from "firebase/database";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { isNumeric } from 'validator';
import { firebaseDatabase } from '..';

const initialState = {
    cost: '',
    condoCost: '',
    min: '',
    max: '',
};
export default function PriceBucketModal({ show, onClose, editData }) {
    const [values, setValues] = useState(initialState);

    useEffect(() => {
        if (editData)
            setValues({...editData});
    }, [editData]);

    function handleClose() {
        setValues(initialState);
        onClose();
    }

    function handleSaveBucket(e) {
        e.preventDefault();
        if (editData) {
            update(ref(firebaseDatabase, `settings/priceBuckets/${editData.id}`), {
                ...values,
            }, err => {
                console.log({err});
            });
        } else {
            const newBucketKey = push(child(ref(firebaseDatabase), 'settings/priceBuckets')).key;

            set(ref(firebaseDatabase, `settings/priceBuckets/${newBucketKey}`), {
                id: newBucketKey,
                ...values,
            }, err => {
                console.log({err});
            });
        }
        handleClose();
    }

    function handleValueChange(e) {
        if(isNumeric(e.target.value || '0')) {
            setValues({
                ...values,
                [e.target.name]: Number(e.target.value),
            });
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
        >
            <Form onSubmit={handleSaveBucket}>
                <Modal.Body>
                    <Form.Group className="d-flex mb-2 justify-content-between align-items-center" controlId="cost">
                        <Form.Label>Cost:</Form.Label>
                        <Form.Control isInvalid={values.condoCost > values.cost} isValid={values.cost && values.condoCost && values.condoCost < values.cost} className="w-50" autoComplete="off" type="text" name="cost" value={values.cost} onChange={handleValueChange}/>
                    </Form.Group>
                    <Form.Group
                        className="d-flex mb-2 justify-content-between align-items-center"
                        controlId="condoCost"
                    >
                        <Form.Label>Condo Cost:</Form.Label>
                        <Form.Control isInvalid={values.condoCost > values.cost} isValid={values.cost && values.condoCost && values.condoCost < values.cost} className="w-50" autoComplete="off" type="text" name="condoCost" value={values.condoCost} onChange={handleValueChange}/>
                    </Form.Group>
                    <Form.Group className="d-flex mb-2 justify-content-between align-items-center" controlId="minSqft">
                        <Form.Label>Min sqft:</Form.Label>
                        <Form.Control isInvalid={(values.min && values.max) && values.min > values.max} isValid={values.min < values.max} className="w-50" autoComplete="off" type="text" name="min" value={values.min} onChange={handleValueChange}/>
                    </Form.Group>
                    <Form.Group className="d-flex mb-2 justify-content-between align-items-center" controlId="maxSqft">
                        <Form.Label>Max sqft:</Form.Label>
                        <Form.Control isInvalid={(values.min && values.max) && values.min > values.max} isValid={values.min < values.max} className="w-50" error autoComplete="off" type="text" name="max" value={values.max} onChange={handleValueChange}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" disabled={values.min > values.max || values.condoCost > values.cost}>Save</Button>
                    {values.cost < values.condoCost &&
                        <div className="alert alert-danger">"Cost" must be greater than "Condo Cost"</div>
                    }
                    {values.max < values.min &&
                        <div className="alert alert-danger">"Min sqft" must be less than "Max sqft"</div>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
