import Nav from './Nav';

export default function Layout({ children }) {
    return (
        <div className="container">
            <Nav />
            <div className="m-3">{children}</div>
        </div>
    );
}
