import { useEffect, useState } from 'react';
import { ref, onValue, push, child, update, remove } from 'firebase/database';
import { MdPublish } from 'react-icons/md';
import { CgRemove } from 'react-icons/cg';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { firebaseDatabase } from '../..';

export default function Publish() {

    function handlePublish() {
        console.log("publish vercel");
    }

    return (
        <div>
            <button
                className="btn btn-primary d-flex align-items-center"
                onClick={handlePublish}
            >
                <MdPublish className="me-2" />
                Publish Changes
            </button>
        </div>
    );
}
