import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { logout } from '..';

export default function Nav() {
    const location = useLocation();

    return (
        <ul className="nav nav-pills my-4">
            <li className="nav-item">
                <Link
                    className={cx('nav-link', { active: location.pathname === '/price-buckets' })}
                    to="/price-buckets"
                >
                    Price Buckets
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className={cx('nav-link', { active: location.pathname === '/year-buckets' })}
                    to="/year-buckets"
                >
                    Year Buckets
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className={cx('nav-link', { active: location.pathname === '/services' })}
                    to="/services"
                >
                    Services
                </Link>
            </li>
            <li className="nav-item">
                <Link
                    className={cx('nav-link', { active: location.pathname === '/publish' })}
                    to="/publish"
                >
                    Publish
                </Link>
            </li>
            <li className="nav-item">
                <Link className={cx('nav-link')} to="/" onClick={logout}>
                    Logout
                </Link>
            </li>
        </ul>
    );
}
