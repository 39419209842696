import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { ref, onValue, push, child, update, remove } from 'firebase/database';
import { AiOutlinePlus } from 'react-icons/ai';
import { CgRemove } from 'react-icons/cg';
import { RiEditLine } from 'react-icons/ri';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { firebaseDatabase } from '../..';
import PriceBucketModal from '../../modals/PriceBucketModal';
import sortBy from '../../utils/sortBy';

export default function YearBuckets() {
    const [buckets, setBuckets] = useState([]);
    const [addingBucket, setAddingBucket] = useState(false);
    const [editingBucket, setEditingBucket] = useState(null);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        onValue(
            ref(firebaseDatabase, 'settings/yearBuckets'),
            (snapshot) => {
                if (snapshot.exists() && snapshot.val()) {
                    setBuckets(Object.values(snapshot.val()));
                } else {
                    setBuckets([]);
                }
            },
            (err) => {
                console.log({ err });
            }
        );
    }, []);
    useEffect(() => {
        let overlapFound = false;
        let sortedBuckets = buckets.sort(sortBy('min'));
        for (let i = 0; i < sortedBuckets.length; i++) {
            for (let j = i + 1; j < sortedBuckets.length; j++) {
                if (
                    ((sortedBuckets[i].min <= sortedBuckets[j].max && sortedBuckets[i].max >= sortedBuckets[j].min) ||
                    (sortedBuckets[j].min <= sortedBuckets[i].max && sortedBuckets[j].max >= sortedBuckets[i].min)) && overlapFound === false
                ) {
                    overlapFound = true;
                }
            }
        }
        setShowWarning(overlapFound);
    }, [buckets]);

    function handleAddBucket() {
        setAddingBucket(true);
    }

    function handleDeleteBucket(id) {
        remove(ref(firebaseDatabase, `settings/yearBuckets/${id}`));
    }

    function handleEditBucket(id) {
        setEditingBucket(id);
    }

    return (
        <div>
            <div className="d-flex flex-column">
                {buckets.map((b) => {
                    return (
                        <div
                            key={b.id}
                            className="p-3 mb-4 border border-dark-subtle rounded shadow-sm d-flex align-items-center justify-content-between"
                        >
                            <div>
                                <div>
                                    <strong className='text-success'>${b.cost}</strong> (Condo: <strong className='text-success'>${b.condoCost}</strong>)
                                </div>
                                <div className="my-2">
                                    <BsArrowDownShort /> {b.min} sqft - {b.max} sqft{' '}
                                    <BsArrowUpShort />
                                </div>
                            </div>
                            <div className="d-flex">
                                <button
                                    className="btn btn-outline-danger btn-sm d-flex align-items-center me-3"
                                    onClick={() => handleDeleteBucket(b.id)}
                                >
                                    <CgRemove className="me-2" /> Delete
                                </button>
                                <button
                                    className="btn btn-outline-primary btn-sm d-flex align-items-center"
                                    onClick={() => handleEditBucket(b.id)}
                                >
                                    <RiEditLine className="me-2" /> Edit
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
            {showWarning && (
                <div className="alert alert-warning">Warning two buckets sqft ranges overlap</div>
            )}
            <button
                className="btn btn-secondary d-flex align-items-center"
                onClick={handleAddBucket}
            >
                <AiOutlinePlus className="me-2" />
                add bucket
            </button>
            <PriceBucketModal isNew={addingBucket} onClose={() => setAddingBucket(false)} />
            <PriceBucketModal
                bucketKey={editingBucket}
                bucketData={buckets.find((b) => b.id === editingBucket)}
                onClose={() => setEditingBucket(null)}
            />
            {/* <Modal show={editingBucket} onHide={} size='sm' dialogClassName="w-90">
                <Modal.Body>
                    <Form onSubmit={handleSaveBucket}>
                        <Form.Group className="d-flex mb-2 justify-content-between" controlId="cost">
                            <Form.Label>Cost:</Form.Label>
                            <Form.Control className="w-50" autoComplete="off" type="text" />
                        </Form.Group>
                        <Form.Group className="d-flex mb-2 justify-content-between" controlId="condoCost">
                            <Form.Label>Condo Cost:</Form.Label>
                            <Form.Control className="w-50" autoComplete="off" type="text" />
                        </Form.Group>
                        <Form.Group className="d-flex mb-2 justify-content-between" controlId="minSqft">
                            <Form.Label>Min sqft:</Form.Label>
                            <Form.Control className="w-50" autoComplete="off" type="text" />
                        </Form.Group>
                        <Form.Group className="d-flex mb-2 justify-content-between" controlId="maxSqft">
                            <Form.Label>Max sqft:</Form.Label>
                            <Form.Control className="w-50" autoComplete="off" type="text" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit">Save</Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
}
