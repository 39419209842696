import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { ref, onValue, push, child, update, remove } from 'firebase/database';
import { AiOutlinePlus } from 'react-icons/ai';
import { CgRemove } from 'react-icons/cg';
import { RiEditLine } from 'react-icons/ri';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { firebaseDatabase } from '../..';
import PriceBucketModal from '../../modals/PriceBucketModal';
import sortBy from '../../utils/sortBy';

export default function PriceBuckets() {
    const [buckets, setBuckets] = useState([]);
    const [bucketModalOpen, setBucketModalOpen] = useState(false);
    const [bucketModalData, setBucketModalData] = useState(null);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        onValue(
            ref(firebaseDatabase, 'settings/priceBuckets'),
            (snapshot) => {
                if (snapshot.exists() && snapshot.val()) {
                    const snapshotBuckets = Object.values(snapshot.val()).sort(sortBy('min'));
                    setBuckets(snapshotBuckets);
                } else {
                    setBuckets([]);
                }
            },
            (err) => {
                console.log({ err });
            }
        );
    }, []);
    useEffect(() => {
        let overlapFound = false;
        // let buckets = buckets.sort(sortBy('min'));
        for (let i = 0; i < buckets.length; i++) {
            for (let j = i + 1; j < buckets.length; j++) {
                if (
                    ((buckets[i].min <= buckets[j].max && buckets[i].max >= buckets[j].min) ||
                        (buckets[j].min <= buckets[i].max && buckets[j].max >= buckets[i].min)) &&
                    overlapFound === false
                ) {
                    overlapFound = true;
                }
            }
        }
        setShowWarning(overlapFound);
    }, [buckets]);

    function handleDeleteBucket(id) {
        remove(ref(firebaseDatabase, `settings/priceBuckets/${id}`));
    }

    function handleEditBucket(id) {
        setBucketModalData(buckets.find((b) => b.id === id));
        setBucketModalOpen(true);
    }

    return (
        <div>
            <div className="d-flex flex-column">
                {buckets.map((b) => {
                    return (
                        <div
                            key={b.id}
                            className="p-3 mb-4 border border-dark-subtle rounded shadow-sm d-flex align-items-center justify-content-between"
                        >
                            <div>
                                <div className="my-2">
                                    <BsArrowDownShort /> {b.min} sqft - {b.max} sqft{' '}
                                    <BsArrowUpShort />
                                </div>
                                <div>
                                    <strong className="text-success">${b.cost}</strong> (Condo:{' '}
                                    <strong className="text-success">${b.condoCost}</strong>)
                                </div>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant='outline-danger'
                                    size='sm'
                                    className="d-flex align-items-center me-3"
                                    onClick={() => handleDeleteBucket(b.id)}
                                >
                                    <CgRemove className="me-2" /> Delete
                                </Button>
                                <Button
                                    variant='outline-primary'
                                    size='sm'
                                    className="d-flex align-items-center"
                                    onClick={() => handleEditBucket(b.id)}
                                >
                                    <RiEditLine className="me-2" /> Edit
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
            {showWarning && (
                <div className="alert alert-warning">Warning two buckets sqft ranges overlap</div>
            )}
            <Button
                variant="secondary"
                className="d-flex align-items-center"
                onClick={() => setBucketModalOpen(true)}
            >
                <AiOutlinePlus className="me-2" />
                add bucket
            </Button>
            <PriceBucketModal
                show={bucketModalOpen}
                editData={bucketModalData}
                onClose={() => setBucketModalOpen(false)}
            />
        </div>
    );
}
